
import useNavigation from '@/composables/useNavigation';
import { useRoute } from 'vue-router';
import { useMainStore } from '@/stores/main.store';
import { storeToRefs } from 'pinia';

export default {
    name: 'DeskBookedConfirmation',
    setup() {
        const { goHome, gotoQrScanner } = useNavigation();
        const route = useRoute();
        const store = useMainStore();

        const { userType, guestModel } = storeToRefs(store);

        function restart() {
            if (route.query.isVisitor) {
                gotoQrScanner();
            } else {
                goHome();
            }
        }

        return {
            goHome,
            guestModel,
            userType,
            store,
            restart,
        };
    },
};
